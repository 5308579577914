# Sprites from Balatro: https://store.steampowered.com/app/2379780/Balatro/
import card0 from "./deck_balatro/0.png"
import card1 from "./deck_balatro/1.png"
import card2 from "./deck_balatro/2.png"
import card3 from "./deck_balatro/3.png"
import card4 from "./deck_balatro/4.png"
import card5 from "./deck_balatro/5.png"
import card6 from "./deck_balatro/6.png"
import card7 from "./deck_balatro/7.png"
import card8 from "./deck_balatro/8.png"
import card9 from "./deck_balatro/9.png"
import card10 from "./deck_balatro/10.png"
import card11 from "./deck_balatro/11.png"
import card12 from "./deck_balatro/12.png"
import card13 from "./deck_balatro/13.png"
import card14 from "./deck_balatro/14.png"
import card15 from "./deck_balatro/15.png"
import card16 from "./deck_balatro/16.png"
import card17 from "./deck_balatro/17.png"
import card18 from "./deck_balatro/18.png"
import card19 from "./deck_balatro/19.png"
import card20 from "./deck_balatro/20.png"
import card21 from "./deck_balatro/21.png"
import card22 from "./deck_balatro/22.png"
import card23 from "./deck_balatro/23.png"
import card24 from "./deck_balatro/24.png"
import card25 from "./deck_balatro/25.png"
import card26 from "./deck_balatro/26.png"
import card27 from "./deck_balatro/27.png"
import card28 from "./deck_balatro/28.png"
import card29 from "./deck_balatro/29.png"
import card30 from "./deck_balatro/30.png"
import card31 from "./deck_balatro/31.png"
import card32 from "./deck_balatro/32.png"
import card33 from "./deck_balatro/33.png"
import card34 from "./deck_balatro/34.png"
import card35 from "./deck_balatro/35.png"
import card36 from "./deck_balatro/36.png"
import card37 from "./deck_balatro/37.png"
import card38 from "./deck_balatro/38.png"
import card39 from "./deck_balatro/39.png"
import card40 from "./deck_balatro/40.png"
import card41 from "./deck_balatro/41.png"
import card42 from "./deck_balatro/42.png"
import card43 from "./deck_balatro/43.png"
import card44 from "./deck_balatro/44.png"
import card45 from "./deck_balatro/45.png"
import card46 from "./deck_balatro/46.png"
import card47 from "./deck_balatro/47.png"
import card48 from "./deck_balatro/48.png"
import card49 from "./deck_balatro/49.png"
import card50 from "./deck_balatro/50.png"
import card51 from "./deck_balatro/51.png"
import cardBack from "./deck_balatro/back.png"
import cardGuide from "./deck_balatro/guide.png"
import cardReserve from "./deck_balatro/reserve.png"
import cardDead from "./deck_balatro/dead.png"
import cardReady from "./deck_balatro/ready.png"

export urls = [ card0, card1, card2, card3, card4, card5, card6, card7, card8, card9,
card10, card11, card12, card13, card14, card15, card16, card17, card18,
card19, card20, card21, card22, card23, card24, card25, card26, card27,
card28, card29, card30, card31, card32, card33, card34, card35, card36,
card37, card38, card39, card40, card41, card42, card43, card44, card45,
card46, card47, card48, card49, card50, card51 ]

export other = [
  cardBack
  cardGuide
  cardReserve
  cardDead
  cardReady
]

export back = cardBack
export guide = cardGuide
export reserve = cardReserve
export dead = cardDead
export ready = cardReady
